.login-parent-div {
  height: 100vh;
  overflow-y: auto;
}

.login-child-div {
  text-align: center;
  justify-content: center;
  margin-top: 70px;
  display: grid;
}

.login-main-title {
  font-size: 20px;
  font-weight: 600;
  color: #333333;
}

.login-main-title-parent-div {
  display: flex;
  text-align: center;
  justify-content: center;
}

.login-main-title-child-div {
  text-align: center;
  justify-content: center;
  border-radius: 3px;
  padding: 10px;
  width: 87%;
  display: flex;
}

.login-child-title {
  font-size: 22px;
  font-weight: 600;
  color: #333333;
  margin-left: 4px;
}

.login-form {
  max-width: 500px;
  height: 100%;
  margin-top: 20px;
  box-shadow: 0px 0px 10px 0px #00000040;
  border: 1px solid #b5b2ae29;
  border-radius: 5px;
  padding: 25px;
}

.login-form .ant-col.ant-form-item-label {
  padding: 0px !important;
}

.login-form-item-txt-align {
  text-align: left;
}

.login-form-item-label {
  font-size: 13px;
  font-weight: 600;
  color: #333333;
}

.login-form-item-input {
  width: 300px;
  height: 32px;
}

.login-form-item-btn {
  background-color: #212529;
  color: #ffffff;
  width: 300px;
  height: 40px;
  font-weight: 600;
  border-color: #808080;
  border-width: 1px;
  border-radius: 3px;
}

.login-form-item-btn:hover {
  background-color: #9a9b9c !important;
}

.login-form-item-ahref-label {
  color: #039;
  font-size: 13px;
  cursor: pointer;
}

.login-form-item-alert {
  width: 300px;
  text-align: center;
  white-space: pre-line;
}

.ant-layout {
  background: #ffffff;
}

.ant-layout.history-layout {
  /* height: 40vh; */
  background: #fff !important;
  padding: 0px;
  border-radius: 10px;
  margin-top: 0px;
}

.ant-layout.main-layout {
  background: #ffffff !important;
  overflow: hidden;
  /* height: calc(100% - 104.016px); */
  margin: 0px;
  padding: 0px;
  height: 100%;
}

.ant-layout.history-layout-tb {
  flex: none;
  background: #ffffff !important;
  /* height: 94vh; */
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.ant-layout.order-layout-tb {
  flex: none;
  background: #ffffff !important;
  /* height: 68vh; */
  min-height: 50vh;
  display: flex;
  flex-direction: column;
}

.ant-layout-header {
  height: 55px;
  line-height: 38px;
  padding: 9px 15px;
}

.header-menu-div {
  float: left;
  display: flex;
}

.header-logo-label {
  color: white;
  font-size: 22px;
  margin-left: 4px;
  font-weight: bold;
}

.header-userNm-div {
  float: right;
  display: flex;
}

.header-icon {
  color: white;
  font-size: 20px;
}

.header-icon-label {
  color: white;
  font-size: 14px;
  margin-left: 3px;
  font-weight: bold;
}

.drawer-close-div {
  text-align: right;
  padding: 10px;
}

.drawer-close-icon {
  font-size: 20px;
}

.footer-layout {
  flex: auto;
  border-radius: 2px;
  width: 1800;
  margin: 0px auto;
}

/* .footer-layout-div {
  align-content: center;
} */

.history-tb .ant-table-row > td {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 5px 15px !important;
}

.my-page-menu {
  border-top: 2px solid #00000061;
  padding: 30px;
  width: 300px;
  text-align: left;
  background-color: #00000021;
  cursor: pointer;
}

.history-btn {
  background-color: #f0f0f0;
  color: #212529;
  height: 30px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0px 0px 3px 0px #808080;
  border: 1px solid #808080;
}

.history-search-btn {
  background-color: #ffffff;
  color: #017bc5;
  width: 50px;
  height: 30px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 13px;
  box-shadow: 0px 0px 10px 0px #808080;
  border: 1px solid #017bc5;
}

.order-delete-btn {
  background-color: #ffffff;
  color: #017bc5;
  width: '100%';
  height: 30px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 13px;
  box-shadow: 0px 0px 10px 0px #808080;
  border: 1px solid #017bc5;
}

.history-btn-disabled {
  background-color: #615e5e4d !important;
  color: #212529;
  height: 30px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0px 0px 10px 0px #808080;
  border: 1px solid #808080;
}

.go-to-top-btn {
  background-color: #fff;
  color: #333333;
  width: 130px;
  height: 40px;
  font-weight: 600;
  border-color: #333333;
  border-width: 2px;
  border-radius: 20px;
}

.home-upload-btn {
  background-color: #017bc5;
  color: #fff;
  width: 135px;
  height: 30px;
  font-size: 12px;
  font-weight: bold;
  border-color: #85a0b8;
  border-width: 1px;
  border-radius: 5px;
  /* margin: 5px auto; */
  cursor: pointer;
}

.estimate-cond-btn {
  background-color: #017bc5;
  color: #ffff;
  width: 70px;
  height: 30px !important;
  font-weight: 600;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0px 0px 10px 0px #808080;
  border: 1px solid #017bc5;
  cursor: pointer;
}

.estimate-cond-btn:hover,
.home-upload-btn:hover,
.admin-estimate-btn:hover {
  background-color: #6489c4d1 !important;
  color: #ffff !important;
  border: 1px solid #6489c4d1 !important;
}

/* ローディング状態 */
.btn-loading {
  background-color: #6489c4d1;
  color: #ffff;
  width: 80px;
  height: 30px !important;
  font-weight: 600;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0px 0px 10px 0px #808080;
  border: 1px solid #6489c4d1;
  cursor: pointer;
}

/* ローディング状態に特別なスタイルを追加 */
/* .btn-loading::after {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  border: 2px dotted white;
  border-top: 2px dotted transparent;
  border-radius: 50%;
  position: absolute;
  right: 63px;
  transform: translateY(-50%);
  animation: spin 0.8s linear infinite;
  cursor: pointer;
} */

/* アニメーション効果 */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.history-btn:hover,
.go-to-top-btn:hover {
  background-color: #f0ebeb !important;
  color: #212529 !important;
  box-shadow: 0px 0px 4px 0px #808080 !important;
  border: 1px solid #808080 !important;
}

.my-page-menu:hover {
  border-top: 2px solid #00000061;
  padding: 30px;
  width: 300px;
  text-align: left;
  background-color: #efe3e38c;
  cursor: pointer;
}

.history-tb.ant-table-wrapper .ant-table-thead > tr > th,
.admin-history-tb.ant-table-wrapper .ant-table-thead > tr > th {
  position: relative;
  color: #333333 !important;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  /* padding: 10px; */
  border-radius: 1px;
  background: #f0f0f0 !important;
  border-bottom: 1px solid #d9d9d9 !important;
}

.upload-file-div {
  text-align: center;
}

.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 0px;
}
.ant-table-wrapper .ant-table-tbody > tr {
  height: 50px;
}
.upload-file-tb.ant-table-wrapper .ant-table-thead > tr > th {
  position: relative;
  color: #333333 !important;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  /* padding: 10px; */
  border-radius: 1px;
  background: #f0f0f0 !important;
  border-bottom: 1px solid #d9d9d9 !important;
}
.ant-table-wrapper .ant-table-thead th::before {
  width: 1px !important;
  height: 2.2em !important;
  background-color: #d9d9d9 !important;
}

.ant-table-wrapper .ant-table-tbody .ant-table-row.ant-table-row-selected > .ant-table-cell {
  background: #dfeaffa1;
  border-bottom: 1px solid #d9d9d973 !important;
}

.logout-div:hover {
  cursor: pointer;
}

.logout-div-label:hover {
  text-decoration: underline;
  cursor: pointer;
}

.ant-menu-light {
  background: none !important;
  margin-left: -14px;
}

.ant-menu-horizontal,
.ant-menu-item::after,
.ant-menu-submenu::after {
  border: none !important;
}

img.ant-image-img.menu-icon:hover {
  background: gold;
  border-radius: 2px;
  cursor: pointer;
}

.history-tb-label {
  font-size: 13px;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #001529;
  border-color: #001529;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
  background-color: #001529;
}

.ant-drawer .ant-drawer-body {
  padding-left: 5px;
  padding-right: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #fffdfd !important;
}

.ant-drawer-content-wrapper {
  width: 250px !important;
}

.ant-drawer-left > .ant-drawer-content-wrapper {
  top: 55px;
}

.ant-drawer-header {
  display: none !important;
}

.main-drawer-div {
  display: flex;
  padding: 12px;
  cursor: pointer;
  font-size: 13px;
  font-weight: bold;
  color: #333333;
}
.main-drawer-icon {
  font-size: 21px;
}

.main-drawer-div:hover,
.main-drawer-icon:hover {
  background-color: #5c5c5c;
  color: #ffffff !important;
  cursor: pointer;
}
.circle {
  width: 100px;
  height: 100px;
  background-color: #00000030;
  border-radius: 50%;
  display: flex;
  justify-content: center; /* 中央揃え（子要素用） */
  align-items: center; /* 中央揃え（子要素用） */
  margin: 7px auto; /* ページ中央寄せ */
}

.ant-table-wrapper .ant-table .ant-table-header {
  position: sticky;
  top: 0px;
  z-index: 1;
}
thead.ant-table-thead {
  position: sticky;
  top: 0px;
  z-index: 1;
}

.upload-file-tb.ant-table-wrapper .ant-table {
  /* padding-left: 8px; */
  /* height: 690px; */
  margin: 0px auto;
  line-height: 1.2;
  /* min-width: 1400px; */
  padding-left: 16px;
  padding-right: 16px;
}

.history-tb.ant-table-wrapper .ant-table {
  padding-left: 8px;
  height: 690px;
  margin: 0px auto;
  line-height: 1.2;
  /* min-width: 1400px; */
}

.admin-history-tb.ant-table-wrapper .ant-table {
  /* width: 1880px; */
  /* height: 790px; */
  padding-left: 16px;
  padding-right: 16px;
  /* height: 690px; */
  margin: 0px auto;
  line-height: 1.2;
  /* min-width: 1400px; */
}
.admin-history-tb .ant-table-row > td {
  max-width: 0;
  overflow: hidden;
  /* text-overflow: ellipsis; */
  white-space: nowrap;
  padding: 0px 15px !important;
}

.upload-file-tb.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 12px 0px -10px 20px !important;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 10px 0px 10px 20px !important;
}

.ant-table-pagination {
  display: block !important;
}

.order-tb.ant-table-wrapper .ant-table {
  padding-left: 8px;
  width: 1600px;
  height: 750px;
  margin: 0px auto;
}

.ant-layout-footer {
  /* padding: 10px 50px; */
  background-color: #fff;
}

.ant-select-selector {
  background-color: #fffdfd !important; /* 背景色 */
  border: 1px solid #ddd !important; /* 境界線 */
  border-radius: 4px !important; /* 角丸 */
  box-shadow: none !important; /* シャドウを無効化 */
  height: 30px !important; /* 高さ調整 */
  /* width: 120px !important; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px !important; /* 固定幅 */
  max-width: 200px !important; /* 最大幅を制限 */
  overflow: hidden; /* はみ出した部分を非表示 */
  text-overflow: ellipsis; /* 省略記号 (...) を表示 */
}

/* ドロップダウン矢印部分の背景 */
.ant-select .ant-select-arrow {
  display: flex;
  align-items: center;
  color: black;
  font-style: normal;
  line-height: 1;
  text-align: center;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0%;
  inset-inline-start: auto;
  inset-inline-end: 0px;
  height: 30px;
  margin-top: 0px;
  font-size: 10px;
  background-color: #fffdfd;
  width: 28px;
  border-radius: 4px;
  border-right: 1px solid #ddd !important; /* 境界線 */
  border-top: 1px solid #ddd !important; /* 境界線 */
  border-bottom: 1px solid #ddd !important; /* 境界線 */
}

span.anticon.anticon-down.ant-select-suffix {
  padding-left: 10px;
}

.estimate-form .ant-col.ant-form-item-label {
  padding: 2px !important;
}

.ant-select-single .ant-select-selector {
  font-size: 12px;
}

.react-datepicker-popper {
  display: contents;
}

.react-datepicker-wrapper {
  display: none !important;
}

svg.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker {
  border: none !important;
}

.react-datepicker-div {
  box-shadow: 0px 0px 10px 0px #00000040;
  padding: 0px;
  width: 240px;
  margin: 0px auto;
}

.copy-paste-div-mrleft {
  margin-left: 5px;
}

.copy-paste-div-pointer,
.copy-paste-div-pointer:hover {
  cursor: pointer;
}

.copy-paste-div {
  background-color: #fffdfd;
  color: black;
  width: 55px;
  height: 27px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 7px;
  border: 1px solid #8080806b;
  text-align: center;
  align-content: center;
  box-shadow: 0px 0px 10px 0px #ccc;
  cursor: pointer;
}

.copy-paste-div-selected {
  background-color: #fffdfd;
  color: black;
  width: 55px;
  height: 27px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 7px;
  border: 1px dashed #42a5f5;
  text-align: center;
  align-content: center;
  cursor: pointer;
}

.copy-paste-div:hover,
.copy-paste-div-selected:hover {
  background-color: #fffdfd;
  color: rgb(78, 76, 76);
  width: 55px;
  height: 27px;
  font-weight: 600;
  border-radius: 5px;
  font-size: 7px;
  border: 1px solid darkgray;
  text-align: center;
  align-content: center;
  box-shadow: 0px 0px 10px 0px #e0d7d76b;
  cursor: pointer;
}

.date-picker-div {
  position: absolute;
  top: 25%;
  left: 130px;
  /* left: -240px; */
  z-index: 10;
  background-color: #ffff;
  border: 1px solid #dddddd;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 0px #8080807a;
}
.circle-status {
  width: 22px;
  height: 22px;
  background-color: #00000030;
  border-radius: 50%;
  display: flex;
  justify-content: center; /* 中央揃え（子要素用） */
  align-items: center; /* 中央揃え（子要素用） */
  cursor: pointer;
}

.circle-status-div {
  border: 1px solid #dddddd;
  border-radius: 25px;
  display: flex;
  justify-content: center; /* 中央揃え（子要素用） */
  align-items: center; /* 中央揃え（子要素用） */
  padding: 3px;
  width: 120px;
  text-align: center;
  margin: 0px auto;
}

.circle-status-green {
  width: 22px;
  height: 22px;
  background-color: #388e3c;
  border-radius: 50%;
  display: flex;
  justify-content: center; /* 中央揃え（子要素用） */
  align-items: center; /* 中央揃え（子要素用） */
  cursor: pointer;
}

.circle-status-orange {
  width: 22px;
  height: 22px;
  background-color: #ffb74d;
  border-radius: 50%;
  display: flex;
  justify-content: center; /* 中央揃え（子要素用） */
  align-items: center; /* 中央揃え（子要素用） */
  cursor: pointer;
}

.circle-status-blue {
  width: 22px;
  height: 22px;
  background-color: #4db6ac;
  border-radius: 50%;
  display: flex;
  justify-content: center; /* 中央揃え（子要素用） */
  align-items: center; /* 中央揃え（子要素用） */
  cursor: pointer;
  border: 2px dotted white;
  border-top: 2px dotted transparent;
  transform: translateY(-50%);
  animation: spin 0.8s linear infinite;
}

.circle-status-red {
  width: 22px;
  height: 22px;
  background-color: #d32f2f;
  border-radius: 50%;
  display: flex;
  justify-content: center; /* 中央揃え（子要素用） */
  align-items: center; /* 中央揃え（子要素用） */
  cursor: pointer;
}

.del-btn {
  cursor: pointer;
  font-size: 20px;
  color: #d9363ee0;
}

.admin-estimate-btn {
  background-color: #017bc5;
  color: #fff;
  width: 140px;
  height: 30px;
  font-weight: 600px;
  border-radius: 5px;
  font-size: 13px;
  box-shadow: 0px 0px 10px 0px #808080;
  border: 1px solid #017bc5;
  margin-right: 10px;
}

.ant-pagination-total-text,
.ant-pagination .ant-pagination-item {
  font-size: 13px;
  color: black;
  font-weight: 'bold';
}

.ant-pagination .ant-select-selector {
  /* border: 1px solid #a5a5a5 !important; */
  border-radius: 5px !important;
  height: 30px !important;
  padding: 0 3px !important;
  width: 100px !important;
  background-color: white !important;
  text-align: center;
}

.ant-pagination .ant-select-arrow {
  background-color: white !important;
}

.ant-pagination-item a {
  color: black !important;
}

.drag-area {
  border: 1px solid #808080 !important;
  justify-content: left;
  align-items: center;
  text-align: center;
  width: 25%;
  border-radius: 5px;
  margin-left: 20px;
  padding: 3px;
}
.footer-div {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 20%;
}

.home-upload-no-data-btn {
  background-color: #00000030;
  color: #333333;
  width: 220px;
  height: 28px;
  font-size: 12px;
  font-weight: bold;
  border-color: #333333;
  border-width: 1px;
  border-radius: 5px;
  margin: 7px auto;
  cursor: pointer;
}
.home-tilte-div {
  justify-content: left;
  align-items: center;
  padding: 10px;
  background-color: #f0f0f0;
}
.home-tilte-btn-div {
  justify-content: left;
  align-items: center;
  padding-left: 6px;
  padding-top: 10px;
  padding-bottom: 0px;
  margin-left: 10px;
}
.history-btn-icon {
  font-size: 14px;
  color: #212529;
}
.estimate-amt-icon {
  font-size: 22px;
  color: #212529;
  cursor: pointer;
}

.ant-modal-close {
  top: 1px !important;
  color: white !important;
}

.top-table-title {
  font-size: 12px;
  /* color: #333333; */
  color: red;
}
.ant-table-wrapper .ant-table-title {
  padding: 0px;
  margin-left: 5px;
  margin-bottom: 4px;
}
.history-search-div {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  padding-right: 16px;
}
/* 画面幅が600px以下の場合、右のdivを非表示 */
@media (max-width: 600px) {
  .history-search-div {
    display: none;
  }
}
.order-complete-btn {
  margin-left: 10px;
  width: 70px;
  color: #388e3c;
  border: 1px solid #388e3c;
  height: 30px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0px 0px 3px 0px #808080;
}
.order-complete-btn:hover {
  color: #fff !important;
  background-color: #388e3c !important;
  border: 1px solid #388e3c !important;
}
.estimate-amt-change-btn {
  margin-left: 10px;
  width: 70px;
  /* background-color: #017bc5; */
  color: #017bc5;
  border: 1px solid #017bc5;
  height: 30px;
  font-weight: 500;
  border-radius: 5px;
  font-size: 12px;
  box-shadow: 0px 0px 3px 0px #808080;
}
.estimate-amt-change-btn:hover {
  color: #fff !important;
  background-color: #017bc5 !important;
  border: 1px solid #017bc5 !important;
}

/* 20250127 */
/* General table styling */
/* Ensures that the table stays within the scrollable area */
.responsive-table {
  width: 100%;
  overflow: hidden; /* Hides anything outside the container */
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* The table scroll container */
.responsive-table .ant-table-container {
  height: 100%;
  overflow: auto;
  position: relative;
  display: block;
}

/* Sticky header for the table */
.responsive-table .ant-table-thead {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  height: 64px !important;
}

/* Table body with max-height and overflow auto */
.responsive-table .ant-table-body {
  max-height: calc(100vh - 55px - 60px); /* Adjust this value based on header height and any UI elements */
  overflow-y: auto; /* Makes the body scrollable */
}

/* Hides anything outside the scroll container */
.responsive-table .ant-table-body::-webkit-scrollbar {
  display: none; /* Hides the scrollbar */
}

/* .order-conditions-div {
  display: grid;
  padding: 7px;
  min-height: 150px;
  height: auto;
  overflow-wrap: break-word;
}

@media (max-width: 1200px) {
  .order-conditions-div {
    min-height: 130px;
  }
}

@media (max-width: 992px) {
  .order-conditions-div {
    min-height: 110px;
  }
}

@media (max-width: 768px) {
  .order-conditions-div {
    min-height: 90px;
  }
}

@media (max-width: 576px) {
  .order-conditions-div {
    min-height: 80px;
  }
} */

/* .responsive-table .ant-table-header {
  height: 64px !important;
} */
/* .responsive-table .ant-table-thead > tr {
  height: 64px !important;
}

.responsive-table .ant-table-thead th {
  height: 64px !important;
  line-height: 64px !important;
} */

.ant-table-pagination {
  min-height: 32px; /* 最小の高さを設定 */
}

.footer-layout-div {
  display: flex;
  /* flex-wrap: wrap; */
  /* justify-content: center;  */
  /* Center the content */
  /* align-items: center; */
  gap: 10px;
  /* width: 100%; */
}

.footer-layout-div .ant-space {
  width: 100%;
  flex-wrap: wrap;
  justify-content: left !important;
}

.footer-layout-div .ant-btn {
  width: 100%;
  min-width: 100px;
}

.footer-layout-div .ant-btn p {
  margin: 0;
  font-size: 12px;
  white-space: nowrap;
}

@media (max-width: 715px) {
  .footer-layout-div .ant-btn {
    width: 100%;
    max-width: 200px;
  }

  .footer-layout-div .ant-btn p {
    font-size: 12px;
  }

  .home-upload-btn {
    margin-bottom: 8px;
  }

  .home-upload-btn {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .footer-layout-div .ant-btn {
    width: 100px;
  }

  .footer-layout-div .ant-btn p {
    font-size: 11px;
  }

  .home-upload-btn {
    margin-bottom: 8px;
  }

  .home-upload-btn {
    font-size: 11px;
  }
}

/* 20250128 */
.admin-tilte-btn-div {
  justify-content: left;
  align-items: center;
  padding-left: 6px;
  padding-top: 10px;
  padding-bottom: 0px;
  margin-left: 10px;
}

.admin-search-div {
  display: flex;
  justify-content: right;
  align-items: center;
  width: 100%;
  padding-right: 16px;
  padding-top: 10px;
}
/* 画面幅が600px以下の場合、右のdivを非表示 */
@media (max-width: 600px) {
  .admin-search-div {
    display: none;
  }
}

@media (max-width: 1150px) {
  .top-table-title {
    display: none;
  }

  .upload-file-tb.ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 10px 0px 10px 20px !important;
  }
}

/* .upload-table-border { */
/* border-right: 1px solid rgb(193, 191, 191) !important; */
/* border-right: 1px solid #8b8b8b !important; */
/* box-shadow: 1px 15px 0px 0px #8b8b8b;
  background-color: #f0f0f0 !important;
} */

.wrap-text {
  white-space: normal;
  word-wrap: break-word; /* 長い単語を折り返す */
  overflow-wrap: break-word; /* 同上（最新の仕様） */
  font-weight: 300; /* 細い文字 */
  color: rgba(0, 0, 0, 0.5); /* 淡いグレー */
  font-size: 12px;
}

.form-item-with-dotted-line {
  border-bottom: 1px dotted #ccc; /* Add dotted line at the bottom */
  padding-bottom: 20px; /* Optional: space between input and line */
  margin-bottom: 20px; /* Optional: space between Form.Item elements */
  padding-left: 5px;
}

.form-item-with-dotted-line:last-child {
  border-bottom: none; /* Remove the dotted line for the last item */
}

.form-item-with-dotted-line .ant-form-item-explain {
  margin-bottom: 10px; /* Add space between input and help text */
}

.inquiry-help-text {
  margin-top: 5px;
  color: gray;
  font-size: 10px;
}

.inquiry-button {
  background-color: #ffd700;
  color: black;
  font-weight: bold;
  font-size: 16px;
  border: none;
  width: 35%;
  height: 40px;
}

.inquiry-button:hover {
  background-color: #ffa500 !important;
  color: black !important;
  border: none;
}

/* .popup-select-input-style {
  width: auto !important;
  min-width: 120px;
} */

.inquiry-form {
  max-width: 750px;
  height: 100%;
  margin-top: 30px;
  box-shadow: 0px 0px 10px 0px #00000040;
  border: 1px solid #b5b2ae87;
  border-radius: 5px;
  padding: 20px;
}

td.ant-table-cell.upload-table-border.ant-table-cell-fix-left.ant-table-cell-fix-left-last {
  border-right: 1px solid #c0bebe;
}
.ant-table-wrapper .ant-table-expanded-row-fixed {
  margin: 0px;
}
.deliveryDate.ant-picker .ant-picker-input > input {
  width: 75px;
}

.deliveryDate {
  height: 30px;
  border-radius: 4px;
}

.err-detail-popover .ant-popover-inner {
  background-color: #ffcccc !important; /* 好きな背景色に変更 */
  box-shadow: 0 6px 16px 0 rgb(0 0 0 / 32%), 0 3px 6px -4px rgb(0 0 0 / 32%), 0 9px 28px 8px rgb(0 0 0 / 0%);
  /* width: 420px; */
}
.err-detail-popover .ant-popover-arrow::before {
  background-color: #ffcccc !important; /* 矢印の色も変更 */
}

.err-detail-popover-div {
  font-size: 13px;
  color: #333333;
}

.err-detail-popover-a,
.err-detail-popover-a:hover {
  font-size: 11px;
  color: red;
}
